'use strict';

(function() {

    var styleIsLoaded = false

    var $jsonp = (function(){
        var that = {};

        that.send = function(src, options) {
            var callback_name = options.callbackName || 'callback',
                on_success = options.onSuccess || function(){},
                on_timeout = options.onTimeout || function(){},
                timeout = options.timeout || 10; // sec

            var timeout_trigger = window.setTimeout(function(){
                window[callback_name] = function(){};
                on_timeout();
            }, timeout * 1000);

            window[callback_name] = function(data){
                window.clearTimeout(timeout_trigger);
                on_success(data);
            }

            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = src;

            document.getElementsByTagName('head')[0].appendChild(script);
        }

        return that;
    })();

    function initEventsWidget(widget) {
        var html = "",
            widgetId = widget.getAttribute("w-id"),
            widgetToken = widget.getAttribute("w-token"),
            widgetEnvironment = widget.getAttribute("w-environment"),
            widgetAssetVersion = widget.getAttribute("w-asset-version"),
            widgetNumberOfEvents = widget.getAttribute("w-num-events"),
            widgetTarget = widget.getAttribute("w-target"),
            styleUrl = '',
            assetVersion = '00',
            script = document.currentScript,
            url = script.src.split("/"),
            baseUrl = url[0] + "//" + url[2] + "/"

        if (widgetAssetVersion != null) {
            assetVersion = widgetAssetVersion
        }

        if (widgetNumberOfEvents != null) {
            widgetNumberOfEvents = parseInt(widgetNumberOfEvents)
        }

        if (widgetEnvironment === 'local') {
            styleUrl = baseUrl + 'css/widgets.css'
        } else {
            styleUrl = 'https://a.tikkio.com/static/1.0.9' + assetVersion  + '/css/widgets.min.css'
        }

        if (styleIsLoaded === false) {
            var head = document.getElementsByTagName('head')[0];
            var style = document.createElement('link');
            style.href = styleUrl;
            style.type = 'text/css';
            style.rel = 'stylesheet';
            head.append(style);
            styleIsLoaded = true;
        }

        $jsonp.send(baseUrl + "widget-feed/" + widgetId + "/" + widgetToken, {
            callbackName: 'callback_' + widgetId,
            onSuccess: function(json) {
                widget.innerHTML = buildHtml(json, widgetNumberOfEvents, widgetTarget)
            },
            onTimeout: function() {
                widget.innerHTML = "en feil oppstod"
            },
            timeout: 5
        });
    }

    function buildHtml(data, widgetNumberOfEvents, widgetTarget) {
        var ctr = 0,
            html = ''

        for (var id in data.events) {
            if (widgetNumberOfEvents && ++ctr > widgetNumberOfEvents) {
                break;
            }

            if (data.events[id].url === undefined) {
                continue;
            }

            html += '<div class="tikkio-widget-event">'
            html += '<div class="tikkio-widget-event-inside">'
            if (data.events[id].ribbon != '') {
                html += '<div class="tikkio-widet-ribbon-box">'
                html += '<div class="tikkio-widget-ribbon"><span>' + data.events[id].ribbon + '</span></div>'
                html += '</div>'
            }
            html += '<div class="tikkio-widget-event-image" style="background-image: url(\'' + data.events[id].image + '\'); background-size: cover; background-position: center center; background-repeat: no-repeat no-repeat;"></div>'
            html += '<div class="tikkio-widget-event-inner-content">'
            html += '<div class="tikkio-widget-event-title" title="' + data.events[id].name + '">' + data.events[id].name + '</div>'
            html += '<div class="location">'
            html += data.events[id].date + '<br>' + data.events[id].location
            html += '</div>'
            html += '</div>'
            html += '<a ' + (widgetTarget == 'blank' ? 'target="_blank" ' : '') + 'class="tikkio-widget-buy-ticket-link" href="' + data.events[id].url + '"><div class="tikkio-widget-buy-ticket">' + data.buy_ticket  + '</div></a>'
            html += '</div>'
            html += '</div>'
        }
        return html
    }

    var widgets = document.getElementsByClassName("tikkio-widget-events");
    for (var i = 0; i < widgets.length; i++) {
        initEventsWidget(widgets[i])
    }

})();